import { ThemeOptions } from "@mui/material";
import { iconSets } from "../src/builder/icons";
import { defaultIconSet, theme } from "../src/wysiwyg/schemas/theme";

export interface DefaultThemeOptions {
  headersFontFamily: string;
  bodyFontFamily: string;
}

export const defaultFontsConstant = {
  body: "Source Sans 3",
  headers: "Sk-Modernist-Regular",
};

export const defaultAlphaConstant = {
  alphaDark: 0.2,
  alphaLight: 0.3,
};

export const defaultColorsConstant = {
  primaryDefault: "#6174CE",
  secondaryDefault: "#191E29",
  whiteDefault: "#fff",
  blackDefault: "#000",
  panelsDefault: "#F7F8FA",
  inputsUnderlineDefault: "#E5E8EF",
  validationSuccess: "#1b5e20",
  validationWarning: "#c55b04",
  validationError: "#bb2a1f",
};

export const getDefaultThemeSettings = (
  defaultOptions: DefaultThemeOptions,
): ThemeOptions => ({
  getMediaForSettings: () => {},
  icons: iconSets[defaultIconSet],
  defaultSpacing: {
    md: {
      page: {
        edges: "1rem",
        content: "2rem",
      },
    },
  },
  settings: {
    ...theme.settings,
    fonts: {
      ...(theme.settings.fonts || {}),
      headers: {
        ...(theme.settings.fonts?.headers || {}),
        fontFamily: defaultOptions?.headersFontFamily,
      },
      body: {
        ...(theme.settings.fonts?.body || {}),
        fontFamily: defaultOptions?.bodyFontFamily,
      },
    },
  },
});
